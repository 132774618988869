import React, { useEffect, useState } from "react";
import "./Certificados.css";
import { connect } from "react-redux";
import { createSelector, bindActionCreators } from "@reduxjs/toolkit";
import {
  fetchPostsSucceeded,
  fetchMorePostsSucceeded,
} from "../../actions/actions";
import { fetchPostsPreview } from "../../sagas/posts";
import { getPostEntries, getPostEntriesTotal } from "../../selectors/posts";
import Button from "../../components/button/Button";
import PageBanner from "../../components/pageBanner/PageBanner";
import {
  CERTIFICADO_ASALVADOR_CATEGORY_ID,
  CERTIFICADO_ASALVADOR_NIVEL_1_CATEGORY_ID,
  CERTIFICADO_ASALVADOR_NIVEL_2_CATEGORY_ID,
  CERTIFICADO_ASALVADOR_NIVEL_3_CATEGORY_ID,
  CERTIFICADO_ASALVADOR_COMPROMISSO_CATEGORY_ID,
} from "../../constants/constants.api";
import Loader from "../../components/loader/Loader";
import FilterSection from "../../components/filterSection/FilterSection";
import AtribuidoItem from "../../components/atribuidoItem/AtribuidoItem";

const bannerImage = require("../../assets/banners/banner1.jpg");

const filtersArray = [
  { title: "COMPROMISSO DE ACESSIBILIDADE", value: "compromisso" },
  { title: "NÍVEL I – BÁSICO", value: "nivel1" },
  { title: "NÍVEL II – FUNCIONAL", value: "nivel2" },
  { title: "EXCELÊNCIA", value: "nivel3" },
];

const getCategoryNum = (filter) => {
  switch (filter) {
    case "compromisso":
      return `${CERTIFICADO_ASALVADOR_COMPROMISSO_CATEGORY_ID}`;
    case "nivel1":
      return `${CERTIFICADO_ASALVADOR_NIVEL_1_CATEGORY_ID}`;
    case "nivel2":
      return `${CERTIFICADO_ASALVADOR_NIVEL_2_CATEGORY_ID}`;
    case "nivel3":
      return `${CERTIFICADO_ASALVADOR_NIVEL_3_CATEGORY_ID}`;
    default:
      return `${CERTIFICADO_ASALVADOR_CATEGORY_ID}`;
  }
};

const PER_PAGE = 9;

const Certificados = ({
  onFetchPostsSucceeded,
  onFetchMorePostsSucceeded,
  posts,
  total,
}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterValue, setFilterValue] = useState("nivel1");

  useEffect(() => {
    setLoading(true);
    const callback =
      page === 1 ? onFetchPostsSucceeded : onFetchMorePostsSucceeded;
    fetchPostsPreview(
      callback,
      getCategoryNum(filterValue),
      page,
      PER_PAGE,
      () => setLoading(false)
    );
  }, [
    onFetchPostsSucceeded,
    onFetchMorePostsSucceeded,
    page,
    setLoading,
    filterValue,
  ]);

  const hasMorePages = page * PER_PAGE < total;
  return (
    <div className="certificado-page">
      <PageBanner
        title="Certificados de Acessibilidade"
        description="Todos os certificados atribuídos"
        image={bannerImage}
      />
      {posts && posts.length > 0 && (!loading || page > 1) ? (
        <div className="certificado-content">
          <FilterSection
            filtersArray={filtersArray}
            filterValue={filterValue}
            onFilterClick={setFilterValue}
            columnGap={12}
            orangeHover
          />
          <div className="certificado-grid">
            {posts.map((ele, index) => (
              <AtribuidoItem post={ele} key={`certificado-${index}`} darkMode />
            ))}
          </div>
          {hasMorePages &&
            (loading ? (
              <Loader loadMore />
            ) : (
              <div className="certificado-more-section">
                <Button centered onClick={() => setPage(page + 1)}>
                  CARREGAR MAIS
                </Button>
              </div>
            ))}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export const mapStateToProps = createSelector(
  getPostEntries,
  getPostEntriesTotal,
  (posts, total) => ({
    posts,
    total,
  })
);

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onFetchPostsSucceeded: fetchPostsSucceeded,
      onFetchMorePostsSucceeded: fetchMorePostsSucceeded,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Certificados);
