const niveisAcessibilidade = [
  {
    id: 0,
    title: "Espaço Público",
    description:
      "Cumpre as condições de usufruto do espaço público em todas as valências de utilização no âmbito da legislação em vigor.",
    image: require("../assets/icons/espacopublico.png"),
  },
  {
    id: 1,
    title: "Nível 1 - Funcional",
    description:
      "Cumpre condições de visita e usufruto do espaço em todas as valências da sua atividade principal para acesso público.",
    image: require("../assets/icons/nivel1-150x150.png"),
  },
  {
    id: 2,
    title: "Nível 2 - Amigável",
    description:
      "Cumpre o nível anterior e ainda disponibiliza materiais e conteúdos que reforcem o bem-estar dos utilizadores do espaço certificado. Pode ainda, promover condições de acessibilidade para além do âmbito da legislação em vigor, tais como o atendimento e acompanhamento personalizado, a criação de materiais informativos e informatizados ou ainda, o reforço nas complementaridades da acessibilidade universal capaz de integrar todos nas diferentes componentes sociais, culturais e turísticas.",
    image: require("../assets/icons/nivel2-150x150.png"),
  },
  {
    id: 3,
    title: "Nível 3 - Excelência",
    description:
      "Cumpre os níveis anteriores e cria, ainda, condições para se poder exercer a atividade profissional com as suas diferentes limitações. Este nível, diretamente ligado à empregabilidade, assume um papel determinante no conceito mais universal da promoção da acessibilidade, e, consequentemente, considerado de excelência.",
    image: require("../assets/icons/nivel3-150x150.png"),
  },
    {
    id: 10,
    title: "COMPROMISSO DE ACESSIBILIDADES",
    description:
      "Os signatários assumem o compromisso em melhorar as acessibilidades da entidade da qual são responsáveis, reconhecendo a importância de criar um ambiente inclusivo, onde todas as pessoas possam desfrutar plenamente dos seus direitos e participar ativamente na sociedade.",
    image: require("../assets/icons/compromisso-150.png"),
    customStyle: { width: "90px", height: "90px" },
  },
  {
    id: 11,
    title: "NÍVEL I. BÁSICO",
    description:
      "Cumpre condições de visita e usufruto do espaço a um nível básico, garantindo o compromisso de continuar a melhorar o espaço e passar para nível seguinte.",
    image: require("../assets/icons/basico-150.png"),
  },
  {
    id: 22,
    title: "NÍVEL II. FUNCIONAL",
    description:
      "Cumpre o nível anterior: Promove acessibilidade consoante a legislação atual.",
    image: require("../assets/icons/funcional-150.png"),
  },
  {
    id: 33,
    title: "NÍVEL III. EXCELÊNCIA",
    description:
      "Cumpre os níveis anteriores e cria, ainda, condições para se poder exercer a atividade profissional com as suas diferentes limitações. Adicionalmente, cumpre boas práticas de acessibilidade para além do que está na legislação, para todo o tipo de acessibilidade (visual, auditiva, intelectual, etc.).",
    image: require("../assets/icons/excelencia-150.png"),
  },
];

export default niveisAcessibilidade;
